/** @jsx jsx */
import { jsx, Box, Heading, Text } from "theme-ui"
import {
  AnimatedScrollPrompt,
  LayoutNoSidebar,
  Link,
  SplitScreen,
  SiteLogo,
  ParallaxHeaderWithSvg,
} from "@smerth/gatsby-theme-theme-ui"
import { IoArrowUndoOutline } from "react-icons/io5"
import BigMenu from "../../../components/BigMenu"

function demo() {
  return (
    <LayoutNoSidebar>
      <ParallaxHeaderWithSvg component={<SiteLogo size="inherit" />}>
        <Box variant="layout.constrained" color="chromeText">
          <Heading as="h1">ParallaxHeaderWithSvg full page Demo</Heading>
          <Text variant="ui.9" p={3}>
            <Link
              variant="chrome"
              href="/documentation/parallax-header-with-svg/"
            >
              <IoArrowUndoOutline />
              Back to ParallaxHeaderWithSvg
            </Link>
          </Text>
        </Box>
        <AnimatedScrollPrompt
          color="chromeText"
          background="rgb(0, 0, 0, 0.25)"
          delay="800ms"
        />
      </ParallaxHeaderWithSvg>

      <SplitScreen
        backgroundColorLeft="background"
        backgroundColorRight="text"
        componentLeft={
          <Text as="p" variant="paragraph.lg" color="text" p="80px">
            Irure ea ut cillum non anim irure ea minim nulla sint labore officia
            fugiat pariatur. Duis consequat qui quis irure adipisicing veniam
            magna esse exercitation ex voluptate ea.
          </Text>
        }
        componentRight={
          <Text as="p" variant="paragraph.lg" color="background" p="80px">
            Laborum deserunt proident consectetur cupidatat do ut consequat
            magna nulla elit magna.
          </Text>
        }
      />
      <SplitScreen
        backgroundColorLeft="chrome"
        backgroundColorRight="background"
        componentLeft={
          <Text as="p" variant="paragraph.lg" color="chromeText" p="80px">
            {" "}
            Velit eu incididunt consequat anim occaecat quis sit minim pariatur
            ullamco cupidatat consectetur nulla cupidatat. Duis ad magna
            reprehenderit eu.
          </Text>
        }
        componentRight={
          <Text as="p" variant="paragraph.lg" color="text" p="80px">
            Nostrud cupidatat sit minim deserunt dolor ipsum laborum duis
            exercitation in consequat. Proident minim sit ipsum esse nostrud. Ad
            dolore consequat pariatur nostrud est culpa irure id nulla amet
            fugiat mollit non deserunt.
          </Text>
        }
      />
      <Box color="white" bg="primary" sx={{ position: "relative" }}>
        <BigMenu
          accordionVariant="transparent"
          menuVariant="primary"
          height="xs"
        />
      </Box>
    </LayoutNoSidebar>
  )
}

export default demo
